import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { SideMenu } from './main-menu-items';
import { MainMenuService } from './main-menu.service';
import { BuyGmrxModalComponent } from 'src/app/shared/components/modals/buy-gmrx-modal/buy-gmrx-modal.component';
import { BUTTON_SIZE } from 'src/app/shared/enums';
import { Button } from 'src/app/shared/interfaces';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuComponent {
  @ViewChild(CdkOverlayOrigin) trigger!: CdkOverlayOrigin;

  isSideBarOpen$ = this.mainMenuService.isSideBarOpen$;

  readonly buyGMRXBtn: Button = {
    name: 'BUY GMRX',
    size: BUTTON_SIZE.MD
  };

  sideMenu$ = this.mainMenuService.sideMenu$;
  mobileMenu$ = this.mainMenuService.mobileMenu$;

  constructor(
    private router: Router,
    private dialogService: MatDialog,
    public userService: UserService,
    private mainMenuService: MainMenuService
  ) {}

  buyGMRX() {
    this.closeSidebar();
    this.dialogService.open(BuyGmrxModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-buy-gmrx']
    });
  }

  openRoot() {
    this.router.navigateByUrl('/');
  }

  openProfile() {
    this.closeSidebar();
    this.router.navigateByUrl('profile');
  }

  openDropdown(item: SideMenu) {
    if (item.disabled) {
      return;
    }
    this.mainMenuService.openDropdown(item, this.trigger);
  }

  openSidebar() {
    this.mainMenuService.closeBottomSheet();
    this.mainMenuService.openSidebar();
  }

  closeSidebar() {
    this.mainMenuService.closeSidebar();
  }

  toggleBottomSheet(item: SideMenu) {
    this.closeSidebar();
    this.mainMenuService.toggleBottomSheet(item);
  }
}
