import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, switchMap, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Response, DpaEventEnrollmentData } from '../../interfaces';
import { USER_ROLES } from '../../enums';
import { AuthStateService } from './auth-state.service';
import { AuthRequestService } from './auth-request.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string | null = null;

  private currentUserRolesSrc = new BehaviorSubject<string[]>([]);
  private managerEncodedCredsSrc = new BehaviorSubject<string | null>(null);
  private dpaEventEnrollmentDataSrc = new BehaviorSubject<DpaEventEnrollmentData>({} as DpaEventEnrollmentData);

  dpaEventEnrollmentData$ = this.dpaEventEnrollmentDataSrc.asObservable();

  get managerEncodedCreds(): string | null {
    return this.managerEncodedCredsSrc.value;
  }

  public isLoggedIn$ = this.authStateService.isLoggedIn$;

  constructor(
    private dialogService: MatDialog,
    private toastrService: ToastrService,
    private router: Router,
    private authStateService: AuthStateService,
    private authRequestService: AuthRequestService
  ) {
    this.authStateService.userRolesSnapshot && this.currentUserRolesSrc.next([this.authStateService.userRolesSnapshot]);
  }

  public init(): void {
    this.subscribeOnLoginStatus();
  }

  private subscribeOnLoginStatus() {
    return this.authStateService.isLoggedIn$
      .pipe(
        distinctUntilChanged(),
        tap((isLoggedIn) => {
          if (isLoggedIn) {
            this.checkUtmLink();
            this.checkDpaEventEnrollment(true, false);
          }
        })
      )
      .subscribe();
  }

  private addToMailingList() {
    this.authRequestService.addToMailingList().subscribe((response) => {
      if (response.success) {
        this.toastrService.success('You have been successfully added to the mailing list!');
      }
    });
  }

  checkUtmLink() {
    if (this.authStateService.utmSourceIdSnapshot !== '') {
      this.authRequestService
        .utmSourceIdRequest({ utmSourceId: this.authStateService.utmSourceIdSnapshot })
        .pipe(
          filter((response) => response.success),
          tap(() => {
            localStorage.removeItem('utmSourceId');
          })
        )
        .subscribe();
    }
  }

  checkDpaEventEnrollment(showConnectWalletToastr: boolean = false, showSuccessToastr: boolean = false) {
    this.authRequestService
      .dpaEventEnrollmentRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.dpaEventEnrollmentDataSrc.next(response.data!);
          const url = this.router.url;
          if (!url.includes('events/dpa-claim')) {
            if (response!.data!.hasDpaClaims && !response!.data!.isWalletConnected) {
              if (showConnectWalletToastr) {
                this.toastrService.success(
                  `You’ve already claimed your DPA! Please connect your wallet to receive it.`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
              if (showSuccessToastr) {
                this.toastrService.success(
                  `Congratulations, your wallet is connected successfully. Your DPA will appear in your Fan Passport after the
              event!`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
            }
          }
        })
      )
      .subscribe();
  }

  logout() {
    this.authStateService.clearData();
    this.router.navigateByUrl('/');
    this.dialogService.closeAll();
  }

  performRefreshTokenRequest(refreshToken: string) {
    return this.authRequestService.refreshTokenRequest(refreshToken).pipe(
      filter((response) => response.success && response.data !== null),
      tap((response) => {
        this.authStateService.setAccessToken(response.data!.accessToken);
        this.authStateService.setRefreshToken(response.data!.refreshToken);
      })
    );
  }

  login() {
    this.redirectToAuthModule();
  }

  signup() {
    this.redirectToAuthModule('signup');
  }

  private redirectToAuthModule(type: 'signup' | 'signin' = 'signin') {
    const returnURL = btoa(encodeURIComponent(window.location.origin + '?type=login'));
    const redirectURL = `${environment.authModuleUrl}/${type}?returnURL=${returnURL}&theme=dark`;

    window.location.href = redirectURL;
  }

  checkUserRights(userRoles: string[]) {
    return userRoles.some((roleToCheck) => this.currentUserRolesSrc.getValue().includes(roleToCheck));
  }

  getUserPermissions(credential: unknown): Observable<Response<USER_ROLES[]>> {
    const { email, password } = credential as { email: string; password: string };
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    this.managerEncodedCredsSrc.next(encodedCredentials);
    this.authStateService.setManagerToken(encodedCredentials);

    return this.authRequestService.getUserPermissionsRequest(encodedCredentials).pipe(
      tap((userRolesResponse) => {
        let userRole = USER_ROLES.ROLE_USER;

        if (userRolesResponse.success) {
          const currentUserRoles = userRolesResponse.data;
          this.currentUserRolesSrc.next(currentUserRoles!);
          if (this.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])) {
            userRole = USER_ROLES.ROLE_COLOSSEUM_MANAGER;
          }
        } else {
          this.currentUserRolesSrc.next([userRole]);
        }

        this.authStateService.setUserRoles(userRole);
      })
    );
  }

  navigateToAuthProfile() {
    this.performRefreshTokenRequest(this.authStateService.refreshTokenSnapshot).subscribe((response) => {
      if (response.success) {
        window.open(
          `${environment.authModuleUrl}/profile?theme=dark&accessToken=${response.data.accessToken}`,
          '_blank'
        );
      }
    });
  }
}
