import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { LeaderBoardItem, LeaderboardResponse, Response } from '../../interfaces';
import { environment } from '../../../../environments/environment';
import { SHOW_LOADER } from 'src/app/core/interceptors/loader.interceptor';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  private leaderboardDataSrc = new BehaviorSubject<LeaderboardResponse | undefined>(undefined);
  leaderboardData$ = this.leaderboardDataSrc.asObservable();

  private meLeaderboardDataSrc = new BehaviorSubject<LeaderBoardItem | undefined>(undefined);
  meLeaderboardData$ = this.meLeaderboardDataSrc.asObservable();

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  getLeaderboard(page = 1, shouldShowLoader = true, size = 10): Observable<Response<LeaderboardResponse>> {
    return this.http
      .get<Response<LeaderboardResponse>>(`${environment.gaiminApi}/referral/leaderboard`, {
        params: {
          page,
          size
        },
        context: new HttpContext().set(SHOW_LOADER, shouldShowLoader)
      })
      .pipe(tap((data) => this.leaderboardDataSrc.next(data.data)));
  }

  getLeaderboardById(id: number): Observable<Response<LeaderBoardItem>> {
    return this.http
      .get<Response<LeaderBoardItem>>(`${environment.gaiminApi}/referral/leaderboard/${id}`)
      .pipe(tap((data) => this.meLeaderboardDataSrc.next(data.data)));
  }
}
