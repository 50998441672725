import { ExchangeRateToken } from './smart-wallet.model';

export const LOCALSTORAGE_SETTINGS_KEY = 'smartWalletSettings';

export const convertTokens: ExchangeRateToken[] = [
  { currencyCode: 'GMRX', tokenAddress: '0x998305efDC264b9674178899FFfBb44a47134a76' },
  { currencyCode: 'USDT', tokenAddress: '0x55d398326f99059ff775485246999027b3197955' },
  { currencyCode: 'USDC', tokenAddress: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d' },
  { currencyCode: 'ETH', tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7' },
  { currencyCode: 'BTC', tokenAddress: '0x7130d2a12b9bcfaae4f2634d864a1ee1ce3ead9c' }
];
