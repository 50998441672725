import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response, DpaEventEnrollmentData, UtmSourceRequest, UtmSourceResponse } from '../../interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { USER_ROLES } from '../../enums';
import { AuthStateService } from './auth-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRequestService {
  refreshEndpoint = `/auth/token/refresh`;

  constructor(
    private readonly http: HttpClient,
    private readonly authStateService: AuthStateService
  ) {}

  refreshTokenRequest(refreshToken: string): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.authModuleApi}${this.refreshEndpoint}`,
      {
        refreshToken
      },
      {
        headers: new HttpHeaders().set('skipAuth', 'true')
      }
    );
  }

  dpaEventEnrollmentRequest(): Observable<Response<DpaEventEnrollmentData>> {
    return this.http.get<Response<DpaEventEnrollmentData>>(`${environment.gaiminApi}/dpa-event/user-enrollment`);
  }

  utmSourceIdRequest(dto: UtmSourceRequest): Observable<Response<UtmSourceResponse>> {
    return this.http.post<Response<UtmSourceResponse>>(`${environment.gaiminApi}/users/utm`, dto);
  }

  getUserPermissionsRequest(encodedCredentials: string) {
    const headers = new HttpHeaders().set('Authorization', `Basic ${encodedCredentials}`).set('skipAuth', 'true');
    return this.http.get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
      headers
    });
  }

  addToMailingList() {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/mailing-list`, null);
  }
}
