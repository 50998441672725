import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, distinctUntilChanged, filter, Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoginModalComponent } from '../../components/modals/login-modal/login-modal.component';
import { Response, SubscriptionTypeInfo, DpaEventEnrollmentData, UserSignInCredentials } from '../../interfaces';
import { COLOSSEUM_TIERS, USER_ROLES } from '../../enums';
import { AuthStateService } from './auth-state.service';
import { AuthRequestService } from './auth-request.service';
import { environment } from 'src/environments/environment';
import { UpdateGaiminAccountModalComponent } from '../../components/modals/update-gaimin-account-modal/update-gaimin-account-modal.component';
import { OnboardingModalComponent } from '../../components/modals/onboarding-modal/onboarding-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string | null = null;

  private currentUserRolesSrc = new BehaviorSubject<string[]>([]);
  private managerEncodedCredsSrc = new BehaviorSubject<string | null>(null);
  private acquireUserSubscriptionDataSrc = new BehaviorSubject<SubscriptionTypeInfo | null>({} as SubscriptionTypeInfo);
  private dpaEventEnrollmentDataSrc = new BehaviorSubject<DpaEventEnrollmentData>({} as DpaEventEnrollmentData);

  acquireUserSubscriptionData$ = this.acquireUserSubscriptionDataSrc.asObservable();
  dpaEventEnrollmentData$ = this.dpaEventEnrollmentDataSrc.asObservable();

  get managerEncodedCreds(): string | null {
    return this.managerEncodedCredsSrc.value;
  }
  get acquireUserSubscriptionData(): SubscriptionTypeInfo | null {
    return this.acquireUserSubscriptionDataSrc.value;
  }

  public migrationSuccess$ = new BehaviorSubject<boolean>(false);

  public isLoggedIn$ = this.authStateService.isLoggedIn$;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private dialogService: MatDialog,
    private overlay: Overlay,
    private toastrService: ToastrService,
    private router: Router,
    private authStateService: AuthStateService,
    private authRequestService: AuthRequestService
  ) {
    this.authStateService.userRolesSnapshot && this.currentUserRolesSrc.next([this.authStateService.userRolesSnapshot]);
  }

  public init(): void {
    this.subscribeOnOktaSocialLogin();
    this.subscribeOnMigrationStatus();

    this.subscribeOnLoginStatus();
  }

  private subscribeOnLoginStatus() {
    this.authStateService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.router.navigateByUrl('/content');
        this.checkUtmLink();
        this.checkDpaEventEnrollment(true, false);

        this.checkEmptyProfileInfo();
      }
    });
  }

  private subscribeOnOktaSocialLogin() {
    this.oktaAuthStateService.authState$.subscribe((oktaAuthState) => {
      console.log('oktaAuthState:', oktaAuthState);

      const migrationStarted = localStorage.getItem('migrationStarted');

      if (oktaAuthState.isAuthenticated) {
        if (migrationStarted) {
          this.authStateService.setOktaToken(oktaAuthState.accessToken!.accessToken);
          this.migrateUser(oktaAuthState.accessToken!.accessToken);
        } else {
          this.logoutFromOkta();
        }
      }
    });
  }

  private migrateUser(oktaToken: string) {
    this.authRequestService
      .migratePlatformUser(this.authStateService.unverifiedAccessTokenSnapshot, oktaToken)
      .subscribe((response) => {
        if (response.success) {
          localStorage.removeItem('migrationStarted');
          this.authStateService.setAccessToken(this.authStateService.unverifiedAccessTokenSnapshot);

          this.migrationSuccess$.next(true);
          this.toastrService.success(
            'Congratulations, you have now successfully completed the GAIMIN Single Sign-On (SSO) Migration!',
            '',
            {
              timeOut: 10000
            }
          );

          this.addToMailingList();
        }
      });
  }

  private addToMailingList() {
    this.authRequestService.addToMailingList().subscribe((response) => {
      if (response.success) {
        this.toastrService.success('You have been successfully added to the mailing list!');
      }
    });
  }

  private subscribeOnMigrationStatus() {
    this.authStateService
      .getUnverifiedAccessToken$()
      .pipe(filter((token) => !!token))
      .subscribe((token) => {
        this.authRequestService.checkMigrationStatus(token).subscribe((response) => {
          if (response.data === 'ALREADY_MIGRATED') {
            this.authStateService.setAccessToken(token);
          }

          if (response.data === 'NOT_MIGRATED') {
            this.showUpdateGaiminAccountPopup();
          }

          if (response.data === 'NEW') {
            this.authRequestService.createPlatformUser(token).subscribe(() => {
              this.authStateService.setAccessToken(token);
              this.addToMailingList();
            });
          }
        });
      });
  }

  performOktaLoginRequest(dto: UserSignInCredentials) {
    return this.authRequestService.oktaLoginRequest(dto).pipe(
      tap((response) => {
        this.handleOktaAuthResponse(response);
      })
    );
  }

  private handleOktaAuthResponse(response: Response<any>) {
    if (!response.success && !response.data) return;

    const loginData = response.data.token || response.data;
    this.authStateService.setOktaToken(loginData.accessToken);
    this.migrateUser(loginData.accessToken);
  }

  socialLoginWithOkta() {
    const redirectUrl = this.redirectUrl ? this.redirectUrl : '/content';
    this.oktaAuth.signInWithRedirect({ originalUri: redirectUrl }).then((signInResult) => {
      console.log('Okta sign in result', signInResult);
    });
  }

  acquireAccount(showSuccessPopup: boolean = false, tier?: COLOSSEUM_TIERS) {
    this.authRequestService
      .acquireUserSubscriptionRequest(tier!)
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.acquireUserSubscriptionDataSrc.next(response!.data!);
          if (showSuccessPopup) {
            this.toastrService.success(`Your account has been successfully changed to ${response!.data!.tier}`);
          }
          window.location.reload();
        })
      )
      .subscribe();
  }

  checkEmptyProfileInfo() {
    this.authRequestService
      .checkEmptyProfileInfoRequest()
      .pipe(
        filter((response) => response.success && !response.data),
        tap(() => {
          this.showOnboardingModal();
        })
      )
      .subscribe();
  }

  showOnboardingModal() {
    this.dialogService.open(OnboardingModalComponent, {
      panelClass: 'dialog-overlay-pane'
    });
  }

  checkUtmLink() {
    if (this.authStateService.utmSourceIdSnapshot !== '') {
      this.authRequestService
        .utmSourceIdRequest({ utmSourceId: this.authStateService.utmSourceIdSnapshot })
        .pipe(
          filter((response) => response.success),
          tap(() => {
            localStorage.removeItem('utmSourceId');
          })
        )
        .subscribe();
    }
  }

  updateSubscriptionInfo() {
    this.authRequestService
      .getUserSubscriptionTypeRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.acquireUserSubscriptionDataSrc.next(response!.data!);
        })
      )
      .subscribe();
  }

  checkDpaEventEnrollment(showConnectWalletToastr: boolean = false, showSuccessToastr: boolean = false) {
    this.authRequestService
      .dpaEventEnrollmentRequest()
      .pipe(
        filter((response) => response.success && response.data !== null),
        tap((response) => {
          this.dpaEventEnrollmentDataSrc.next(response.data!);
          const url = this.router.url;
          if (!url.includes('events/dpa-claim')) {
            if (response!.data!.hasDpaClaims && !response!.data!.isWalletConnected) {
              if (showConnectWalletToastr) {
                this.toastrService.success(
                  `You’ve already claimed your DPA! Please connect your wallet to receive it.`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
              if (showSuccessToastr) {
                this.toastrService.success(
                  `Congratulations, your wallet is connected successfully. Your DPA will appear in your Fan Passport after the
              event!`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
            }
          }
        })
      )
      .subscribe();
  }

  logout() {
    this.authStateService.clearData();
    this.acquireUserSubscriptionDataSrc.next(null);
    this.router.navigateByUrl('/');
  }

  logoutFromOkta() {
    this.authStateService.setOktaToken('');

    this.oktaAuth.isAuthenticated().then((isAuthenticated) => {
      if (isAuthenticated) {
        this.oktaAuth.signOut();
      }
    });
  }

  performRefreshTokenRequest(refreshToken: string) {
    return this.authRequestService.refreshTokenRequest(refreshToken).pipe(
      tap((response) => {
        this.authStateService.setAccessToken(response.data!.accessToken);
        this.authStateService.setRefreshToken(response.data!.refreshToken);
      })
    );
  }

  login() {
    this.redirectToAuthModule();
  }

  private redirectToAuthModule() {
    const returnURL = btoa(encodeURIComponent(window.location.origin + '?type=login'));
    const redirectURL = `${environment.authModuleUrl}/signin?returnURL=${returnURL}&theme=dark`;

    window.location.href = redirectURL;
  }

  openOktaLoginPopup(isSignUp: boolean = false, isFullScreen: boolean = false) {
    localStorage.setItem('migrationStarted', 'true');
    this.dialogService.open(LoginModalComponent, {
      panelClass: ['dialog-overlay-pane', isFullScreen ? 'full-screen' : ''],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        isSignUp,
        isFullScreen
      }
    });
  }

  closeOktaLoginPopup() {
    this.dialogService.closeAll();
  }

  checkUserRights(userRoles: string[]) {
    return userRoles.some((roleToCheck) => this.currentUserRolesSrc.getValue().includes(roleToCheck));
  }

  getUserPermissions(credential: unknown): Observable<Response<USER_ROLES[]>> {
    const { email, password } = credential as { email: string; password: string };
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    this.managerEncodedCredsSrc.next(encodedCredentials);
    this.authStateService.setManagerToken(encodedCredentials);

    return this.authRequestService.getUserPermissionsRequest(encodedCredentials).pipe(
      tap((userRolesResponse) => {
        let userRole = USER_ROLES.ROLE_USER;

        if (userRolesResponse.success) {
          const currentUserRoles = userRolesResponse.data;
          this.currentUserRolesSrc.next(currentUserRoles!);
          if (this.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])) {
            userRole = USER_ROLES.ROLE_COLOSSEUM_MANAGER;
          }
        } else {
          this.currentUserRolesSrc.next([userRole]);
        }

        this.authStateService.setUserRoles(userRole);
      })
    );
  }

  private showUpdateGaiminAccountPopup() {
    this.dialogService.open(UpdateGaiminAccountModalComponent, {
      panelClass: ['dialog-overlay-pane'],
      disableClose: true
    });
  }

  cancelMigration() {
    this.authRequestService
      .createPlatformUser(this.authStateService.unverifiedAccessTokenSnapshot)
      .subscribe((response) => {
        if (response.success) {
          this.authStateService.setAccessToken(this.authStateService.unverifiedAccessTokenSnapshot);
        }
      });
  }
}
