import {
  ExchangeRate,
  MoralisNFTResponse,
  MoralisTokenResponse,
  MoralisTransactionResponse,
  SmartWalletSettings
} from '../helpers/smart-wallet.model';

const ACTIONS_SCOPE = '[Smart Wallet]';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SmartWalletActions {
  export class SetAddress {
    static readonly type = `${ACTIONS_SCOPE} Set Address`;
    constructor(public address: string) {}
  }

  export class SetIsLoading {
    static readonly type = `${ACTIONS_SCOPE} Set IsLoading`;
    constructor(public isLoading: boolean) {}
  }

  export class UpdateSettings {
    static readonly type = `${ACTIONS_SCOPE} Update Settings`;
    constructor(public settings: Partial<SmartWalletSettings>) {}
  }

  export class UpdateTokensWithoutPrices {
    static readonly type = `${ACTIONS_SCOPE} Update TokensWithoutPrices`;
    constructor(
      public tokens: MoralisTokenResponse[],
      public nativeBalance: string
    ) {}
  }

  export class SetIsLoadingTokens {
    static readonly type = `${ACTIONS_SCOPE} Set IsLoadingTokens`;
    constructor(public isLoading: boolean) {}
  }

  export class UpdateExchangeRates {
    static readonly type = `${ACTIONS_SCOPE} Update ExchangeRates`;
    constructor(public exchangeRates: ExchangeRate) {}
  }

  export class SetIsLoadingExchangeRates {
    static readonly type = `${ACTIONS_SCOPE} Set IsLoadingExchangeRates`;
    constructor(public isLoading: boolean) {}
  }

  export class UpdateNfts {
    static readonly type = `${ACTIONS_SCOPE} Update NFTS`;
    constructor(public nfts: MoralisNFTResponse[]) {}
  }

  export class SetIsLoadingNfts {
    static readonly type = `${ACTIONS_SCOPE} Set IsLoadingNfts`;
    constructor(public isLoading: boolean) {}
  }

  export class SetNftsPaginationKey {
    static readonly type = `${ACTIONS_SCOPE} Set NftsPaginationKey`;
    constructor(public key: string) {}
  }

  export class ClearNfts {
    static readonly type = `${ACTIONS_SCOPE} Clear NFTS`;
  }

  export class UpdateTransactions {
    static readonly type = `${ACTIONS_SCOPE} Update Transactions`;
    constructor(public transactions: MoralisTransactionResponse[]) {}
  }

  export class SetIsLoadingTransactions {
    static readonly type = `${ACTIONS_SCOPE} Set IsLoadingTransactions`;
    constructor(public isLoading: boolean) {}
  }

  export class Logout {
    static readonly type = `${ACTIONS_SCOPE} Logout`;
  }
}
