import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { smartWalletFadeInOut, smartWalletFiguresInOut } from './smart-wallet-welcome-dialog.animations';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SmartWalletService } from '../../smart-wallet.service';
import { SmartWalletStateService } from '../../store/smart-wallet-state.service';

@Component({
  selector: 'app-smart-wallet-welcome-dialog',
  templateUrl: './smart-wallet-welcome-dialog.component.html',
  styleUrl: './smart-wallet-welcome-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [smartWalletFadeInOut, smartWalletFiguresInOut]
})
export class SmartWalletWelcomeDialogComponent implements AfterViewInit {
  public state$ = new BehaviorSubject<'before' | 'first' | 'second'>('before');
  public isLoading$ = new BehaviorSubject<boolean>(false);

  public form = new FormGroup({
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private readonly dialogRef: MatDialogRef<SmartWalletWelcomeDialogComponent>,
    private smartWalletService: SmartWalletService,
    private smartWalletStateService: SmartWalletStateService
  ) {}

  ngAfterViewInit(): void {
    const wasSigned = this.smartWalletStateService.settingsSnapshot.wasSigned;

    setTimeout(() => this.state$.next(wasSigned ? 'second' : 'first'), 0);
  }

  close() {
    this.dialogRef.close(false);
  }

  toggle() {
    this.state$.next(this.state$.value === 'first' ? 'second' : 'first');
  }

  submit() {
    if (!this.form.valid || !this.form.controls.password.value) {
      return;
    }

    this.isLoading$.next(true);

    this.smartWalletService.configureEmbeddedSigner(this.form.controls.password.value).subscribe({
      next: () => {
        this.smartWalletStateService.updateSettings({ wasSigned: true });
        this.dialogRef.close(true);
      },
      error: () => {
        this.form.controls.password.setErrors({ incorrect: true });
        this.isLoading$.next(false);
      },
      complete: () => {
        this.isLoading$.next(false);
      }
    });
  }
}
