import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { SmartWalletActions } from './smart-wallet.actions';
import {
  ExchangeRate,
  MoralisTokenResponse,
  NFT,
  SmartWalletSettings,
  Token,
  Transaction,
  TRANSACTION_TYPE
} from '../helpers/smart-wallet.model';
import { UserService } from 'src/app/shared/services/user.service';
import { LOCALSTORAGE_SETTINGS_KEY } from '../helpers/smart-wallet.constants';
import {
  formatDate,
  getTransactionAddress,
  getTransactionAsset,
  getTransactionDirection,
  transformTransactionType
} from '../helpers/smart-wallet.helpers';

interface SmartWalletStateModelItem<T> {
  data: T;
  isLoading: boolean;
}

export interface SmartWalletStateModel {
  address: SmartWalletStateModelItem<string>;
  isSendDisabled: boolean;
  settings: SmartWalletSettings;
  tokens: SmartWalletStateModelItem<Token[] | null>;
  GMRXBalance: number | null;
  exchangeRates: SmartWalletStateModelItem<ExchangeRate>;
  nfts: SmartWalletStateModelItem<NFT[]> & {
    paginationKey: string | null;
  };
  transactions: SmartWalletStateModelItem<Transaction[]>;
}

@State<SmartWalletStateModel>({
  name: 'SmartWallet',
  defaults: {
    address: {
      data: '',
      isLoading: false
    },
    isSendDisabled: false,
    settings: {
      isPrivateMode: false,
      wasSigned: false,
      agreedToSendTerms: false,
      selectedCurrency: 'GMRX'
    },
    tokens: {
      data: null,
      isLoading: false
    },
    GMRXBalance: null,
    exchangeRates: {
      data: {},
      isLoading: false
    },
    nfts: {
      data: [],
      isLoading: false,
      paginationKey: null
    },
    transactions: {
      data: [],
      isLoading: false
    }
  }
})
@Injectable()
export class SmartWalletState {
  constructor(private userService: UserService) {}

  @Action(SmartWalletActions.SetAddress)
  SetAddress(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { address }: SmartWalletActions.SetAddress
  ) {
    const state = getState();

    return patchState({ address: { ...state.address, data: address } });
  }

  @Action(SmartWalletActions.SetIsLoading)
  SetIsLoading(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { isLoading }: SmartWalletActions.SetIsLoading
  ) {
    const state = getState();

    return patchState({ address: { ...state.address, isLoading } });
  }

  @Action(SmartWalletActions.UpdateSettings)
  UpdateSettings(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { settings }: SmartWalletActions.UpdateSettings
  ) {
    const state = getState();

    const userId = this.userService.userInfo$.value.id;
    const savedSettings = localStorage.getItem(LOCALSTORAGE_SETTINGS_KEY);
    const newProfileSettings = { ...state.settings, ...settings };

    localStorage.setItem(
      LOCALSTORAGE_SETTINGS_KEY,
      JSON.stringify({ ...JSON.parse(savedSettings || '{}'), [userId]: newProfileSettings })
    );

    return patchState({ settings: newProfileSettings });
  }

  @Action(SmartWalletActions.UpdateTokensWithoutPrices)
  UpdateTokensWithoutPrices(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { tokens, nativeBalance }: SmartWalletActions.UpdateTokensWithoutPrices
  ) {
    const state = getState();
    let formattedTokens: Token[] = [];

    formattedTokens = tokens.map((token: MoralisTokenResponse) => ({
      name: token.name,
      symbol: token.symbol,
      balance: Number((parseInt(token.balance) / 10 ** token.decimals).toFixed(4)),
      decimals: token.decimals,
      contractAddress: token.tokenAddress,
      logo: token.logo
    }));

    const bnbDecimals = 18;
    const balanceInBNB = parseFloat(nativeBalance) / Math.pow(10, bnbDecimals);
    if (balanceInBNB > 0) {
      formattedTokens.unshift({
        name: 'BNB',
        symbol: 'BNB',
        balance: balanceInBNB,
        decimals: bnbDecimals,
        contractAddress: '0x0000000000000000000000000000000000000000',
        logo: './assets/icons/smart-wallet/bnb.png',
        native: true
      });
    }

    const GMRXBalance = tokens.find((token) => token.symbol === 'GMRX')?.balance || 0;

    return patchState({ tokens: { ...state.tokens, data: formattedTokens }, GMRXBalance: +GMRXBalance });
  }

  @Action(SmartWalletActions.SetIsLoadingTokens)
  SetIsLoadingTokens(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { isLoading }: SmartWalletActions.SetIsLoadingTokens
  ) {
    const state = getState();
    return patchState({ tokens: { ...state.tokens, isLoading } });
  }

  @Action(SmartWalletActions.UpdateExchangeRates)
  UpdateExchangeRates(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { exchangeRates }: SmartWalletActions.UpdateExchangeRates
  ) {
    const state = getState();
    return patchState({ exchangeRates: { ...state.exchangeRates, data: exchangeRates } });
  }

  @Action(SmartWalletActions.SetIsLoadingExchangeRates)
  SetIsLoadingExchangeRates(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { isLoading }: SmartWalletActions.SetIsLoadingExchangeRates
  ) {
    const state = getState();
    return patchState({ exchangeRates: { ...state.exchangeRates, isLoading } });
  }

  @Action(SmartWalletActions.UpdateNfts)
  UpdateNfts({ getState, setState }: StateContext<SmartWalletStateModel>, { nfts }: SmartWalletActions.UpdateNfts) {
    const state = getState();

    const formattedNfts: NFT[] = nfts.map((nft) => {
      const metadata = nft.metadata ? JSON.parse(nft.metadata) : {};
      const imageUrl = metadata ? metadata.image || metadata.imageUrl : null;

      return {
        amount: nft.amount || '1',
        tokenId: nft.tokenId,
        name: nft.name,
        metadata,
        imageUrl,
        tokenAddress: nft.tokenAddress,
        symbol: nft.symbol,
        contractType: nft.contractType,
        contractAddress: nft.tokenAddress,
        tokenHash: nft.tokenHash
      };
    });

    return setState({ ...state, nfts: { ...state.nfts, data: [...state.nfts.data, ...formattedNfts] } });
  }

  @Action(SmartWalletActions.SetIsLoadingNfts)
  SetIsLoadingNfts(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { isLoading }: SmartWalletActions.SetIsLoadingNfts
  ) {
    const state = getState();
    return patchState({ nfts: { ...state.nfts, isLoading } });
  }

  @Action(SmartWalletActions.SetNftsPaginationKey)
  SetNftsPaginationKey(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { key }: SmartWalletActions.SetNftsPaginationKey
  ) {
    const state = getState();
    return patchState({ nfts: { ...state.nfts, paginationKey: key } });
  }

  @Action(SmartWalletActions.ClearNfts)
  ClearNfts({ patchState }: StateContext<SmartWalletStateModel>) {
    return patchState({ nfts: { data: [], isLoading: false, paginationKey: null } });
  }

  @Action(SmartWalletActions.UpdateTransactions)
  UpdateTransactions(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { transactions }: SmartWalletActions.UpdateTransactions
  ) {
    const state = getState();

    const formattedTransactions: Transaction[] =
      transactions.map((transaction) => {
        const transactionCategory =
          transaction.category === TRANSACTION_TYPE.CONTRACT_INTERACTION &&
          transaction.nftTransfers[0]?.direction === 'send'
            ? TRANSACTION_TYPE.NFT_SEND
            : transaction.category;

        return {
          asset: getTransactionAsset(transaction, transactionCategory),
          direction: getTransactionDirection(transactionCategory),
          category: transactionCategory,
          type: transformTransactionType(transactionCategory),
          blockStamp: formatDate(transaction.blockTimestamp),
          usd: 0,
          network: 'BSC',
          fee: Number(transaction.transactionFee),
          address: getTransactionAddress(transaction, transactionCategory),
          txid: transaction.hash,
          success: Number(transaction.receiptStatus) === 1
        };
      }) || [];

    return patchState({
      transactions: { ...state.transactions, data: [...state.transactions.data, ...formattedTransactions] }
    });
  }

  @Action(SmartWalletActions.SetIsLoadingTransactions)
  SetIsLoadingTransactions(
    { getState, patchState }: StateContext<SmartWalletStateModel>,
    { isLoading }: SmartWalletActions.SetIsLoadingTransactions
  ) {
    const state = getState();
    return patchState({ transactions: { ...state.transactions, isLoading } });
  }

  @Action(SmartWalletActions.Logout)
  Logout({ patchState }: StateContext<SmartWalletStateModel>) {
    return patchState({
      address: { data: '', isLoading: false },
      tokens: { data: null, isLoading: false },
      GMRXBalance: null,
      exchangeRates: { data: {}, isLoading: false },
      nfts: { data: [], isLoading: false, paginationKey: null },
      transactions: { data: [], isLoading: false }
    });
  }
}
