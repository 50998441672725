import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { WalletConnectService } from '../../../services/wallet-connect.service';
import { BuyGMRXData } from '../../../interfaces';
import { EXCHANGE_CURRENCY } from '../../../enums';
import { CommonModule } from '@angular/common';
import { LazyLoadDirective } from 'src/app/shared/directives/lazy-load.directive';
import { CutWalletAddressPipe } from 'src/app/shared/pipes/cutWalletAddress.pipe';

@Component({
  selector: 'buy-gmrx-modal',
  templateUrl: './buy-gmrx-modal.component.html',
  styleUrls: ['./buy-gmrx-modal.component.scss'],
  imports: [CommonModule, LazyLoadDirective, CutWalletAddressPipe],
  standalone: true
})
export class BuyGmrxModalComponent {
  protected readonly EXCHANGE_CURRENCY = EXCHANGE_CURRENCY;

  imagePath = 'assets/icons/buy-gmrx/';
  dataList: BuyGMRXData[] = [
    {
      name: 'Bybit',
      imageUrl: this.imagePath + 'bybit.svg',
      link: 'https://www.bybit.com/en/trade/spot/GMRX/USDT'
    },
    {
      name: 'KuCoin',
      imageUrl: this.imagePath + 'kuCoin.svg',
      link: 'https://www.kucoin.com/trade/GMRX-USDT'
    },
    {
      name: 'Gate.io',
      imageUrl: this.imagePath + 'gate-io.svg',
      link: 'https://www.gate.io/trade/GMRX_USDT'
    },
    {
      name: 'PancakeSwap',
      imageUrl: this.imagePath + 'pancakeSwap.svg',
      link: 'https://pancakeswap.finance/info/v3/pairs/0x692cb71a629aa90a8ed6c6b6e56ce4d86760dd43'
    }
  ];

  constructor(
    public walletConnectService: WalletConnectService,
    private clipboardService: ClipboardService,
    private toastrService: ToastrService,
    private dialogRef: MatDialogRef<BuyGmrxModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: EXCHANGE_CURRENCY | null
  ) {}

  openExternalLink(link: string) {
    window.open(link, '_blank');
  }

  onCopy(copyText: `0x${string}`) {
    this.clipboardService.copy(copyText);
    this.toastrService.success('The wallet address has been copied.');
  }

  closeModal() {
    this.dialogRef.close();
  }
}
