@if ((isHomePage | async) === false) {
  <app-header />
  <app-main-menu />
  <app-floating-menu />
}

<router-outlet></router-outlet>

@if ((isHomePage | async) === false) {
  <app-loader />
}

