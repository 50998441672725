import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SmartWalletActions } from './smart-wallet.actions';
import { combineLatest, map, Observable } from 'rxjs';
import { SmartWalletSelectors } from './smart-wallet.selectors';
import {
  ExchangeRate,
  MoralisNFTResponse,
  MoralisTokenResponse,
  MoralisTransactionResponse,
  NFT,
  SmartWalletSettings,
  Token,
  TokenWithPrice,
  Transaction
} from '../helpers/smart-wallet.model';

@Injectable({ providedIn: 'root' })
export class SmartWalletStateService {
  address$: Observable<string> = this.store.select(SmartWalletSelectors.address);
  isLoading$: Observable<boolean> = this.store.select(SmartWalletSelectors.isLoading);

  settings$: Observable<SmartWalletSettings> = this.store.select(SmartWalletSelectors.settings);
  isPrivateMode$: Observable<boolean> = this.settings$.pipe(map((settings) => !!settings.isPrivateMode));

  isSendDisabled$: Observable<boolean> = this.store.select(SmartWalletSelectors.isSendDisabled);

  tokensWithoutPrices$: Observable<Token[] | null> = this.store.select(SmartWalletSelectors.tokensWithoutPrices);
  isLoadingTokens$: Observable<boolean> = this.store.select(SmartWalletSelectors.isLoadingTokens);
  GMRXBalance$: Observable<number | null> = this.store.select(SmartWalletSelectors.GMRXBalance);

  exchangeRates$: Observable<ExchangeRate> = this.store.select(SmartWalletSelectors.exchangeRates);
  isLoadingExchangeRates$: Observable<boolean> = this.store.select(SmartWalletSelectors.isLoadingExchangeRates);

  tokens$: Observable<TokenWithPrice[] | null> = combineLatest([this.tokensWithoutPrices$, this.exchangeRates$]).pipe(
    map(([tokensWithoutPrice, exchangeRates]) => this.combineTokensWithExchangeRates(tokensWithoutPrice, exchangeRates))
  );
  totalUSD$ = this.tokens$.pipe(map((tokens) => tokens?.reduce((acc, curr) => acc + curr.usd.usdTotal, 0) || 0));

  nfts$: Observable<NFT[]> = this.store.select(SmartWalletSelectors.nfts);
  isLoadingNfts$: Observable<boolean> = this.store.select(SmartWalletSelectors.isLoadingNfts);
  isLoadingNftsSnapshot: boolean = this.store.selectSnapshot(SmartWalletSelectors.isLoadingNfts);
  nftsPaginationKey$: Observable<string | null> = this.store.select(SmartWalletSelectors.nftsPaginationKey);
  nftsPaginationKeySnapshot: string | null = this.store.selectSnapshot(SmartWalletSelectors.nftsPaginationKey);

  transactions$: Observable<Transaction[]> = this.store.select(SmartWalletSelectors.transactions);
  isLoadingTransactions$: Observable<boolean> = this.store.select(SmartWalletSelectors.isLoadingTransactions);
  isLoadingTransactionsSnapshot: boolean = this.store.selectSnapshot(SmartWalletSelectors.isLoadingTransactions);

  get addressSnapshot(): string {
    return this.store.selectSnapshot(SmartWalletSelectors.address);
  }

  get settingsSnapshot(): SmartWalletSettings {
    return this.store.selectSnapshot(SmartWalletSelectors.settings);
  }

  get tokensWithoutPricesSnapshot(): Token[] | null {
    return this.store.selectSnapshot(SmartWalletSelectors.tokensWithoutPrices);
  }

  get isLoadingTokensSnapshot(): boolean {
    return this.store.selectSnapshot(SmartWalletSelectors.isLoadingTokens);
  }

  constructor(private store: Store) {}

  public getNftByTokenHash$(tokenHash: string): Observable<NFT | null> {
    return this.store.select(SmartWalletSelectors.getNftByTokenHash(tokenHash));
  }

  public getTransactionByTxID$(txid: string): Observable<Transaction | null> {
    return this.store.select(SmartWalletSelectors.getTransactionByTxID(txid));
  }

  public setAddress(address: string) {
    return this.store.dispatch(new SmartWalletActions.SetAddress(address));
  }

  public setIsLoading(isLoading: boolean) {
    return this.store.dispatch(new SmartWalletActions.SetIsLoading(isLoading));
  }

  public updateSettings(settings: Partial<SmartWalletSettings>) {
    return this.store.dispatch(new SmartWalletActions.UpdateSettings(settings));
  }

  public updateTokensWithoutPrices(tokens: MoralisTokenResponse[], nativeBalance: string) {
    return this.store.dispatch(new SmartWalletActions.UpdateTokensWithoutPrices(tokens, nativeBalance));
  }

  public setIsLoadingTokens(isLoading: boolean) {
    return this.store.dispatch(new SmartWalletActions.SetIsLoadingTokens(isLoading));
  }

  public updateExchangeRates(exchangeRates: ExchangeRate) {
    return this.store.dispatch(new SmartWalletActions.UpdateExchangeRates(exchangeRates));
  }

  public setIsLoadingExchangeRates(isLoading: boolean) {
    return this.store.dispatch(new SmartWalletActions.SetIsLoadingExchangeRates(isLoading));
  }

  public updateNfts(nfts: MoralisNFTResponse[]) {
    return this.store.dispatch(new SmartWalletActions.UpdateNfts(nfts));
  }

  public setIsLoadingNfts(isLoading: boolean) {
    return this.store.dispatch(new SmartWalletActions.SetIsLoadingNfts(isLoading));
  }

  public setNftsPaginationKey(key: string) {
    return this.store.dispatch(new SmartWalletActions.SetNftsPaginationKey(key));
  }

  public clearNfts() {
    return this.store.dispatch(new SmartWalletActions.ClearNfts());
  }

  public updateTransactions(transactions: MoralisTransactionResponse[]) {
    return this.store.dispatch(new SmartWalletActions.UpdateTransactions(transactions));
  }

  public setIsLoadingTransactions(isLoading: boolean) {
    return this.store.dispatch(new SmartWalletActions.SetIsLoadingTransactions(isLoading));
  }

  public logout() {
    return this.store.dispatch(new SmartWalletActions.Logout());
  }

  private combineTokensWithExchangeRates(
    tokensWithoutPrice: Token[] | null,
    exchangeRates: ExchangeRate
  ): TokenWithPrice[] | null {
    if (!tokensWithoutPrice) {
      return null;
    }

    return tokensWithoutPrice.map((tokenWithoutPrice) => {
      const rate = exchangeRates[tokenWithoutPrice.symbol];

      if (!rate) {
        return {
          ...tokenWithoutPrice,
          usd: {
            usdPrice: 0,
            usdPriceDisplay: '$N/A',
            usdTotal: 0,
            usdTotalDisplay: '≈ $N/A'
          }
        };
      }

      const usdTotal = rate * tokenWithoutPrice.balance;

      return {
        ...tokenWithoutPrice,
        usd: {
          usdPrice: rate,
          usdPriceDisplay: `$${rate.toFixed(rate < 0.001 ? 6 : 2)}`,
          usdTotal,
          usdTotalDisplay: `≈ $${usdTotal.toFixed(3)}`
        }
      };
    });
  }
}
