import { Injectable } from '@angular/core';
import { bsc, bscTestnet } from 'viem/chains';
import { Address, disconnect, fetchBalance, readContract, watchAccount, writeContract } from '@wagmi/core';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { ToastrService } from 'ngx-toastr';
import {
  BehaviorSubject,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  Subscriber,
  Subscription,
  switchMap,
  take
} from 'rxjs';
import { WalletRequestsService } from './wallet-requests.service';
import { environment } from '../../../environments/environment';
import { getContractABI_DGA, getGmrxContractABI } from '../../../tools/contractAbi';
import { CountNfts, CountNftsObject, DgaNftData, NFTWithCount } from '../interfaces';
import nftsData from 'src/tools/dga-nft.data';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { SubscriptionService } from './subscription.service';
import { AuthService } from './auth/auth.service';
import { COLOSSEUM_TIERS } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class WalletConnectService {
  modal: any;
  isDonatePopoverOpen: boolean = false;
  walletAddress: `0x${string}` | null | Address = null;
  decimals: number = 18;
  isWalletConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  walletGmrxBalance$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  walletBnbBalance$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  walletGmrxUSDBalance$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  walletBnbUSDBalance$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  modalState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  usersNftsWithCount: NFTWithCount[] | null = null;
  tokens: any;

  private activeWalletSrc = new BehaviorSubject<string | null>(null);
  public activeWallet$ = this.activeWalletSrc.asObservable();

  // get wallet id: https://explorer.walletconnect.com/
  availableWalletsList: string[] = [
    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709', // OKX wallet ID
    'a21d06c656c8b1de253686e06fc2f1b3d4aa39c46df2bfda8a6cc524ef32c20c', // Venly wallet ID
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask wallet ID
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0' // Trust wallet ID
  ];

  protected readonly dgaNftsData: DgaNftData[] = nftsData;

  @AutoUnsubscribe()
  getNftsSubscription: Subscription | undefined;

  get smartContractAddress() {
    return environment.gmrxTokenContractAddress as `0x${string}`;
  }

  get dgaSmartContractAddress() {
    return environment.dgaSmartContractAddress as `0x${string}`;
  }

  constructor(
    private toastrService: ToastrService,
    private subsService: SubscriptionService,
    private walletRequestsService: WalletRequestsService,
    private authService: AuthService
  ) {
    const projectId = environment.walletConnectProjectId;
    const chains = environment.production ? [bsc] : [bscTestnet];
    const chainId = environment.production ? bsc.id : bscTestnet.id;

    const metadata = {
      name: 'Colosseum GG',
      description: 'Colosseum Gaimin Gladiators',
      url: 'https://colosseum.gaimingladiators.gg',
      icons: ['https://colosseum.gaimingladiators.gg/assets/gladiators-logo.svg']
    };
    const wagmiConfig = defaultWagmiConfig({
      chains,
      projectId,
      metadata,
      enableCoinbase: false,
      enableInjected: false,
      enableEIP6963: true
    });

    this.modal = createWeb3Modal({
      wagmiConfig,
      projectId,
      chains,
      defaultChain: environment.production ? bsc : bscTestnet,
      includeWalletIds: this.availableWalletsList,
      featuredWalletIds: this.availableWalletsList,
      tokens: {
        [chainId]: {
          address: this.smartContractAddress
        }
      }
    });

    watchAccount(async (account) => {
      this.walletAddress = account.address ? account.address : null;
      this.isWalletConnected$.next(account.isConnected);

      if (this.walletAddress) {
        this.fetchGmrxWalletBalance();
        this.fetchBnbWalletBalance();
        this.authService.isLoggedIn$
          .pipe(
            filter((isLoggedIn) => isLoggedIn),
            take(1)
          )
          .subscribe(async () => {
            this.storeWalletData(this.walletAddress!);
            this.syncAccountTier();
          });
        this.getNftsSubscription = this.getNFTsByWalletAddress().subscribe((response) => {
          this.usersNftsWithCount = response;
        });
        this.activeWalletSrc.next(this.walletAddress);

        await this.subsService.getRemainingLockDays(this.walletAddress!);
        await this.subsService.getStakeTier(this.walletAddress!);
      } else {
        this.walletGmrxBalance$.next(null);
        this.walletBnbBalance$.next(null);
        this.walletGmrxUSDBalance$.next(null);
        this.walletBnbUSDBalance$.next(null);
        this.usersNftsWithCount = null;
        this.getNftsSubscription?.unsubscribe();
      }
    });
  }

  fetchBnbWalletBalance() {
    fetchBalance({
      address: this.walletAddress!,
      chainId: bsc.id
    })
      .then((response) => {
        this.walletBnbBalance$.next(+response.formatted);
      })
      .catch((error) => {
        this.walletBnbBalance$.next(null);
        console.error(error);
        this.toastrService.error('Error fetching bnb balance');
      });
  }

  fetchGmrxWalletBalance() {
    readContract({
      address: this.smartContractAddress,
      abi: getGmrxContractABI(),
      functionName: 'balanceOf',
      args: [this.walletAddress]
    })
      .then((response) => {
        this.walletGmrxBalance$.next(this.formatBalance(response as string, this.decimals));
      })
      .catch((error) => {
        this.walletGmrxBalance$.next(null);
        console.error('Read contract error:', error);
      });
  }

  makeDonate(streamerWallet: string, amount: number) {
    const tokensCount = this.formatTokenCount(amount.toString(), this.decimals);
    return writeContract({
      address: this.smartContractAddress,
      abi: getGmrxContractABI(),
      functionName: 'transfer',
      args: [streamerWallet, tokensCount]
    })
      .then((response) => {
        console.log('Transfer HASH:', response);
        const url = environment.production ? 'https://bscscan.com/tx/' : 'https://testnet.bscscan.com/tx/';
        this.toastrService.success(
          `Successfully donated ${amount} GMRX to streamer!
        <a href='${url + response.hash}' target='_blank'>View details</a>`,
          '',
          {
            enableHtml: true
          }
        );
        return response;
      })
      .catch((error) => {
        console.error('Transfer error:', error);
        this.toastrService.error(`Something went wrong.`);
        throw error;
      });
  }

  openModal(opt: any = {}) {
    this.modal.open(opt);
  }

  /**
   * This method subscribes to the modal state and emits its open/close status.
   *
   * @method subscribeModalSate
   * @return {Observable<boolean>} - An observable that emits the open/close status of the modal.
   */
  subscribeModalSate(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      const state = this.modal.subscribeState((state: any) => {
        subscriber.next(state.open);
        !state.open ? subscriber.complete() : null;
      });
      return () => {
        state();
      };
    });
  }

  async disconnectFromWallet() {
    await disconnect();
  }

  formatBalance(amount: string, decimals: number): number {
    const amountBN = BigInt(amount);
    const divisor = BigInt(10 ** decimals);
    const formatedAmount = amountBN / divisor;

    return Number(formatedAmount);
  }

  formatTokenCount(amount: string, decimals: number): number {
    const amountBN = BigInt(amount);
    const multiplier = BigInt(10 ** decimals);
    const formatedAmount = amountBN * multiplier;

    return Number(formatedAmount);
  }

  private storeWalletData(walletAddress: string) {
    this.walletRequestsService.checkDpaEventEnrollment();
    this.walletRequestsService.storeActiveUserWalletRequest(walletAddress);
  }

  private async syncAccountTier() {
    if (this.isWalletConnected$.getValue()) {
      await this.subsService.getRemainingLockDays(this.walletAddress!);
      await this.subsService.getStakeTier(this.walletAddress!);

      if (
        this.authService.acquireUserSubscriptionData?.tier &&
        this.subsService.userCurrentTier &&
        this.authService.acquireUserSubscriptionData?.tier !== this.subsService.userCurrentTier
      ) {
        this.subsService.userCurrentTier &&
          this.authService.acquireAccount(false, this.subsService.userCurrentTier! as COLOSSEUM_TIERS);
      }
    }
  }

  getNFTsByWalletAddress(nfts: DgaNftData[] = this.dgaNftsData): Observable<NFTWithCount[]> {
    return forkJoin(nfts.map(({ tokenId }) => this.getCountNFTs(tokenId))).pipe(
      switchMap((rawCountNfts: CountNfts[]) => {
        const countNfts: CountNftsObject = this.getCountNftsObject(rawCountNfts);

        const result: NFTWithCount[] = [];

        nfts.forEach((nft: DgaNftData) => {
          const countNft: number = countNfts[nft.tokenId] || 0;

          if (countNft !== 0) {
            result.push({ nftData: nft, count: countNft });
          }
        });

        return of(result);
      })
    );
  }

  private getCountNFTs(nftId: number): Observable<CountNfts> {
    const configuration = this.getConfiguration(nftId);

    return from(readContract(configuration)).pipe(map((count): CountNfts => ({ id: nftId, count: Number(count) })));
  }

  private getCountNftsObject(rawCountNfts: CountNfts[]): CountNftsObject {
    return rawCountNfts.reduce((acc: CountNftsObject, nft: CountNfts) => {
      acc[nft.id] = nft.count;
      return acc;
    }, {});
  }

  private getConfiguration(nftId: number) {
    return {
      address: this.dgaSmartContractAddress,
      abi: getContractABI_DGA(),
      functionName: 'balanceOf',
      args: [this.walletAddress, nftId]
    };
  }

  updateWalletBalances() {
    if (this.isWalletConnected$.getValue()) {
      this.fetchGmrxWalletBalance();
      this.fetchBnbWalletBalance();
    }
  }

  setActiveWallet() {
    this.walletRequestsService.getActiveWalletRequest().subscribe((response) => {
      console.log('active wallet', response);
      if (response.success && response.data) {
        this.activeWalletSrc.next(response.data.address);
      }
    });
  }
}
