import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { BehaviorSubject, filter, interval, startWith, Subscription, switchMap, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AdditionalService } from './shared/services/additional.service';
import { AuthService } from './shared/services/auth/auth.service';
import { NotificationService } from './shared/services/notification.service';
import { NotificationToastComponent } from './shared/components/notification/notification-toast/notification-toast.component';
import { AnalyticsService } from './shared/services/analytics.service';
import { AuthStateService } from './shared/services/auth/auth-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isHomePage = new BehaviorSubject<boolean | null>(null);

  constructor(
    private activatedRoute: ActivatedRoute,
    public additionalService: AdditionalService,
    private authService: AuthService, // do not remove, should be injected,
    private readonly toastrService: ToastrService,
    private readonly notificationService: NotificationService,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router,
    private readonly authStateService: AuthStateService
  ) {}

  @AutoUnsubscribe()
  private timeIntervalSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private isHomePageSub: Subscription = {} as Subscription;

  isLoggedIn = this.authStateService.isLoggedIn$;

  shownNotificationIds: number[] = [];

  ngOnInit() {
    this.authService.init();

    this.activatedRoute.queryParams.subscribe((queryParams) => {
      const accessToken = queryParams['accessToken'];
      const refreshToken = queryParams['refreshToken'];
      if (queryParams['type'] === 'login' && accessToken) {
        this.authStateService.setUnverifiedAccessToken(accessToken);
        this.authStateService.setRefreshToken(refreshToken);
        this.clearQueryParams();
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      let currentRoute = this.activatedRoute;
      let isHomePageLayout = false;

      while (currentRoute.firstChild) {
        currentRoute = currentRoute.firstChild;
        const { layout } = currentRoute.snapshot.data;

        isHomePageLayout = layout === 'landing';
      }

      this.isHomePage.next(isHomePageLayout);
    });

    this.analyticsService.loadGoogleTagManager();

    this.activatedRoute.queryParams.subscribe((params) => {
      const utmSource = params['utmSource'];
      const invitingUserId = params['invitingUserId'];

      if (utmSource) {
        this.authStateService.setUtmSourceId(utmSource);
      }

      if (invitingUserId) {
        this.authStateService.setInvitingUserId(invitingUserId);
        this.authService.login();
      }
    });

    this.isHomePageSub = this.isHomePage.subscribe((isHomePageTemplate) => {
      if (this.authStateService.isLoggedIn && !isHomePageTemplate && isHomePageTemplate !== null) {
        this.timeIntervalSub = interval(60 * 1000)
          .pipe(
            startWith(0),
            takeUntil(this.authStateService.isLoggedIn$.pipe(filter((isLoggedIn) => !isLoggedIn))),
            switchMap(() => this.notificationService.getNotifications())
          )
          .subscribe((data) => {
            data?.forEach((item) => {
              const isNotShown = !this.shownNotificationIds.includes(item.id);

              if (item.new && isNotShown && this.shownNotificationIds.length < 5) {
                this.toastrService.info(undefined, undefined, {
                  toastClass: 'toast-notification ngx-toastr',
                  toastComponent: NotificationToastComponent,
                  payload: item
                });

                this.shownNotificationIds.push(item.id);
              }
            });
          });
      }
    });
  }

  private clearQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: null
    });
  }
}
