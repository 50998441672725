import { FAN_AREA_ITEMS } from 'src/app/shared/enums';

export interface SideMenu {
  title: string;
  redirectLink: string;
  icon: string;
  disabled?: boolean;
  bottomMenu?: boolean;
  dropdown?: IDropdownMenuItem[];
}

export interface IDropdownMenuItem {
  id: FAN_AREA_ITEMS;
  title: string;
  icon: string;
  iconRight?: string;
  path?: string;
  isFloatingMenuItem?: boolean;
  hideDesktop?: boolean;
  badge?: boolean;
}

export const fanAreaMenu: IDropdownMenuItem[] = [
  {
    id: FAN_AREA_ITEMS.NOTIFICATION,
    title: 'Notification',
    icon: 'icon-notification',
    hideDesktop: true,
    path: 'fan-area/notifications'
  }
  // {
  //   id: FAN_AREA_ITEMS.LEADERBOARD,
  //   title: 'Leaderboard',
  //   icon: 'icon-trophy-solid-1',
  //   iconRight: 'icon-from-right',
  //   isFloatingMenuItem: true,
  //   path: 'fan-area/leaderboard',
  // },
  // {
  //   id: FAN_AREA_ITEMS.REFERRALS,
  //   title: 'Invite friends',
  //   icon: 'icon-invite',
  //   iconRight: 'icon-from-right',
  //   isFloatingMenuItem: true,
  //   path: 'fan-area/referrals',
  // }
];

export const sideMenu: SideMenu[] = [
  {
    title: 'Content',
    redirectLink: '/content',
    icon: 'icon-Home-2',
    bottomMenu: true
  },
  {
    title: 'Streams',
    redirectLink: '/streams',
    icon: 'icon-Streams',
    bottomMenu: true
  },
  {
    title: 'Fan Area',
    redirectLink: '/fan-area',
    icon: 'icon-Star',
    bottomMenu: true,
    dropdown: fanAreaMenu,
    disabled: true
  },
  {
    title: 'Rewards',
    redirectLink: '/rewards',
    icon: 'icon-cup'
  },
  {
    title: 'Players',
    redirectLink: '/players',
    icon: 'icon-Nep-Squad'
  },
  {
    title: 'Store',
    redirectLink: '/store',
    icon: 'icon-Market',
    bottomMenu: true
  },
  // {
  //   title: 'Auction',
  //   redirectLink: '/auction',
  //   icon: 'icon-Wallet',
  //   disabled: true,
  //   bottomMenu: false
  // },
  {
    title: 'Charity',
    redirectLink: '/charity',
    icon: 'icon-Dashboard',
    disabled: true
  }
];
