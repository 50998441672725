import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '../../button/button.component';
import { MatDialog } from '@angular/material/dialog';
import { TooltipModule } from 'src/app/shared/directives/tooltip-module/tooltip.module';
import { ReferralsService } from 'src/app/shared/services/fan-area/referrals.service';
import { CommonModule } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BehaviorSubject, Subscription } from 'rxjs';
import { fadeInOutAnimation } from 'src/app/shared/animations/fadeInOut.animation';
import { RewardCoinComponent } from '../../reward-coin/reward-coin.component';
import { WalletConnectService } from 'src/app/shared/services/wallet-connect.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { CutWalletAddressPipe } from 'src/app/shared/pipes/cutWalletAddress.pipe';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { ReferralUserData } from 'src/app/shared/interfaces';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';
import { FloatingMenuService } from 'src/app/core/layout/floating-menu/floating-menu.service';

@Component({
  selector: 'referrals-section',
  templateUrl: './referrals-section.component.html',
  styleUrls: ['./referrals-section.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TooltipModule,
    ClipboardModule,
    NgxPaginationModule,
    RewardCoinComponent,
    CutWalletAddressPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation]
})
export class ReferralsSectionComponent {
  public referralsList$ = this.referralsService.referralsList$;
  public referralLink$ = this.referralsService.referralLink$;
  public userBalance$ = this.referralsService.userBalance$;

  public walletAddress$ = this.walletConnectService.activeWallet$;

  public copied$ = new BehaviorSubject<boolean>(false);

  public paginationConfig$ = new BehaviorSubject<PaginationInstance>({
    id: 'referrals',
    currentPage: 1,
    itemsPerPage: 10
  });

  @AutoUnsubscribe()
  private subscriptions = new Subscription();

  constructor(
    private dialogService: MatDialog,
    private referralsService: ReferralsService,
    private walletConnectService: WalletConnectService,
    private userService: UserService,
    private toastrService: ToastrService,
    private floatingMenuService: FloatingMenuService
  ) {
    this.referralsService.loadReferralLink();
    this.referralsService.loadReferralsList(
      this.paginationConfig$.value.currentPage,
      this.paginationConfig$.value.itemsPerPage
    );
    this.referralsService.loadUserBalance();

    const sub = this.referralsList$.subscribe((referralsList) => {
      if (referralsList) {
        this.paginationConfig$.next({
          ...this.paginationConfig$.value,
          totalItems: referralsList.pagination.totalResults
        });
      }
    });

    this.subscriptions.add(sub);
  }

  public async openTermsModal() {
    const { InviteModalComponent } = await import('../invite-modal/invite-modal.component');
    this.dialogService.open(InviteModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-custom']
    });
  }

  public onPageChange(page: number) {
    this.paginationConfig$.next({
      ...this.paginationConfig$.value,
      currentPage: page
    });

    this.referralsService.loadReferralsList(
      this.paginationConfig$.value.currentPage,
      this.paginationConfig$.value.itemsPerPage
    );
  }

  public copyEvent() {
    this.copied$.next(true);
    this.toastrService.success('Copied to clipboard', undefined, {
      timeOut: 2000,
      toastClass: 'ngx-toastr toast-success border'
    });
    setTimeout(() => {
      this.copied$.next(false);
    }, 2000);
  }

  public getStatusIcon(referral: ReferralUserData, walletConnected: boolean) {
    if (referral.bonusAccrued) {
      return 'icon-Available';
    }

    if (!walletConnected) {
      return 'icon-info';
    }

    return 'icon-history';
  }

  public getStatusText(referral: ReferralUserData, walletConnected: boolean) {
    if (referral.bonusAccrued) {
      return null;
    }

    if (!walletConnected) {
      return 'Connect your wallet to access features';
    }

    return `The payout will be credited in ${referral.numberOfDaysRemaining} days`;
  }

  public connectWallet() {
    if (this.userService.isAccountActive()) {
      this.walletConnectService.openModal();
      this.floatingMenuService.closeDrawer();
    } else {
      this.toastrService.warning('Please verify your email to access The Colosseum!');
    }
  }
}
