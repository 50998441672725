<div class="youtube-player">
  <iframe
    *ngIf="!isBgVisible"
    width="100%"
    height="100%"
    src="https://customer-ud16g7q6qzl6soai.cloudflarestream.com/95c14b37b305403980743fa45cc80b42/iframe?autoplay=true&muted=true"
    title="Gaimin Gladiators Community"
    allow="autoplay"
    allowfullscreen></iframe>
  <div *ngIf="isBgVisible" class="player-bg">
    <picture>
      <source srcset="assets/icons/content/player-bg.webp" type="image/webp" />
      <source srcset="assets/icons/content/player-bg.jpg" type="image/jpeg" />
      <img ngSrc="assets/icons/content/player-bg.jpg" priority fill alt="" />
    </picture>
    <!-- <img ngSrc="assets/icons/content/player-bg.jpg" priority fill alt=""> -->
    <span class="icon-Play" (click)="showVideo()"></span>
  </div>
</div>
