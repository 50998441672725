import { createSelector, Selector } from '@ngxs/store';
import { SmartWalletState, SmartWalletStateModel } from './smart-wallet.state';
import { ExchangeRate, NFT, SmartWalletSettings, Token, Transaction } from '../helpers/smart-wallet.model';

export class SmartWalletSelectors {
  @Selector([SmartWalletState])
  static address(state: SmartWalletStateModel): string {
    return state.address.data;
  }

  @Selector([SmartWalletState])
  static isLoading(state: SmartWalletStateModel): boolean {
    return state.address.isLoading;
  }

  @Selector([SmartWalletState])
  static settings(state: SmartWalletStateModel): SmartWalletSettings {
    return state.settings;
  }

  @Selector([SmartWalletState])
  static isSendDisabled(state: SmartWalletStateModel): boolean {
    return state.isSendDisabled;
  }

  @Selector([SmartWalletState])
  static tokensWithoutPrices(state: SmartWalletStateModel): Token[] | null {
    return state.tokens.data;
  }

  @Selector([SmartWalletState])
  static GMRXBalance(state: SmartWalletStateModel): number | null {
    return state.GMRXBalance;
  }

  @Selector([SmartWalletState])
  static isLoadingTokens(state: SmartWalletStateModel): boolean {
    return state.tokens.isLoading;
  }

  @Selector([SmartWalletState])
  static exchangeRates(state: SmartWalletStateModel): ExchangeRate {
    return state.exchangeRates.data;
  }

  @Selector([SmartWalletState])
  static isLoadingExchangeRates(state: SmartWalletStateModel): boolean {
    return state.exchangeRates.isLoading;
  }

  @Selector([SmartWalletState])
  static nfts(state: SmartWalletStateModel): NFT[] {
    return state.nfts.data;
  }

  @Selector([SmartWalletState])
  static isLoadingNfts(state: SmartWalletStateModel): boolean {
    return state.nfts.isLoading;
  }

  @Selector([SmartWalletState])
  static nftsPaginationKey(state: SmartWalletStateModel): string | null {
    return state.nfts.paginationKey;
  }

  static getNftByTokenHash(tokenHash: string) {
    return createSelector([SmartWalletState], (state: SmartWalletStateModel) => {
      const nfts = state.nfts.data;

      if (!nfts.length || !nfts.find((nft) => nft.tokenHash === tokenHash)) {
        return null;
      }

      return nfts.find((nft) => nft.tokenHash === tokenHash)!;
    });
  }

  @Selector([SmartWalletState])
  static transactions(state: SmartWalletStateModel): Transaction[] {
    return state.transactions.data;
  }

  @Selector([SmartWalletState])
  static isLoadingTransactions(state: SmartWalletStateModel): boolean {
    return state.transactions.isLoading;
  }

  static getTransactionByTxID(txid: string) {
    return createSelector([SmartWalletState], (state: SmartWalletStateModel) => {
      const transactions = state.transactions.data;

      if (!transactions.length || !transactions.find((transaction) => transaction.txid === txid)) {
        return null;
      }

      return transactions.find((transaction) => transaction.txid === txid)!;
    });
  }
}
