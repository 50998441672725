import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilKeyChanged,
  EMPTY,
  expand,
  filter,
  from,
  map,
  Observable,
  switchMap,
  take
} from 'rxjs';
import Openfort, { EmbeddedState, ThirdPartyOAuthProvider, TokenType } from '@openfort/openfort-js';
import { environment } from '../../../environments/environment';
import {
  Estimate,
  ExchangeRate,
  ExchangeRateToken,
  MoralisNFTResponse,
  MoralisResponse,
  MoralisTokenResponse,
  MoralisTransactionResponse,
  SmartWalletSettings,
  TransactionData,
  TransactionResponse
} from './helpers/smart-wallet.model';
import { Response } from 'src/app/shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { SmartWalletWelcomeDialogComponent } from './components/smart-wallet-welcome-dialog/smart-wallet-welcome-dialog.component';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthStateService } from 'src/app/shared/services/auth/auth-state.service';
import { SmartWalletStateService } from './store/smart-wallet-state.service';
import { convertTokens, LOCALSTORAGE_SETTINGS_KEY } from './helpers/smart-wallet.constants';

const gmrxDecimals = 18;

@Injectable()
export class SmartWalletService {
  private readonly chain = 'BNB_CHAIN';

  private openfort!: Openfort;
  private openfortUserSrc = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private authStateService: AuthStateService,
    private dialogService: MatDialog,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private smartWalletStateService: SmartWalletStateService
  ) {
    this.initOpenfort();
  }

  private initOpenfort(): void {
    this.openfort = new Openfort({
      baseConfiguration: {
        publishableKey: environment.openfort.publicKey
      },
      shieldConfiguration: {
        shieldPublishableKey: environment.openfort.shieldApiKey
      }
    });
  }

  public init(): void {
    this.signIn();
    this.subscribeOnProfile();
  }

  private async signIn() {
    this.smartWalletStateService.setIsLoading(true);

    await this.authenticateWithThirdPartyProvider();
    const state = this.openfort.getEmbeddedState();

    if (state === EmbeddedState.READY) {
      this.onAuthenticateSuccess();
    } else {
      const welcomeDialogRef = this.dialogService.open(SmartWalletWelcomeDialogComponent);
      welcomeDialogRef.afterClosed().subscribe(async (enteredPassword: boolean) => {
        if (!enteredPassword) {
          this.router.navigateByUrl(`/content`);
        } else {
          this.onAuthenticateSuccess();
        }
      });
    }
  }

  private async authenticateWithThirdPartyProvider() {
    const accessToken = this.authStateService.accessTokenSnapshot;
    await this.openfort.authenticateWithThirdPartyProvider({
      provider: ThirdPartyOAuthProvider.CUSTOM,
      token: accessToken,
      tokenType: TokenType.CUSTOM_TOKEN
    });
  }

  public configureEmbeddedSigner(recoveryPassword?: string): Observable<void> {
    return from(this.openfort.configureEmbeddedSigner(environment.openfort.chainId, null, recoveryPassword));
  }

  private async onAuthenticateSuccess() {
    this.smartWalletStateService.setIsLoading(true);
    const response = await this.openfort.getAccount();
    const address = response.address;
    const user = await this.openfort.getUser();

    this.openfortUserSrc.next(user);
    this.smartWalletStateService.setAddress(address);
    this.smartWalletStateService.setIsLoading(false);
  }

  private subscribeOnProfile(): void {
    this.userService.userInfo$.pipe(distinctUntilKeyChanged('id')).subscribe(({ id }) => {
      if (!id) {
        return;
      }

      const savedSettings = localStorage.getItem(LOCALSTORAGE_SETTINGS_KEY);
      const profileSettings: SmartWalletSettings = JSON.parse(savedSettings || '{}')[id];

      this.smartWalletStateService.updateSettings(profileSettings || {});
    });
  }

  public loadTokens(): void {
    if (this.smartWalletStateService.isLoadingTokensSnapshot) {
      return;
    }

    this.smartWalletStateService.setIsLoadingTokens(true);

    this.smartWalletStateService.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap((address) => combineLatest([this.loadTokensRequest(address), this.loadNativeTokenRequest(address)]))
      )

      .subscribe({
        next: ([tokensResponse, nativeTokenResponse]) => {
          this.smartWalletStateService.updateTokensWithoutPrices(
            tokensResponse.data?.tokens || [],
            nativeTokenResponse.data?.balance || '0'
          );

          this.smartWalletStateService.setIsLoadingTokens(false);

          this.loadExchangeRates();
        },
        error: () => {
          this.smartWalletStateService.setIsLoadingTokens(false);
        }
      });
  }

  public loadExchangeRates(): void {
    this.smartWalletStateService.setIsLoadingExchangeRates(true);

    const tokens = this.smartWalletStateService.tokensWithoutPricesSnapshot;

    const exchangeRateTokens: ExchangeRateToken[] = tokens
      ? tokens
          .filter(({ symbol }) => !convertTokens.some((token) => token.currencyCode === symbol))
          .map(
            (token) =>
              ({
                currencyCode: token.symbol,
                tokenAddress: token.contractAddress
              }) as ExchangeRateToken
          )
          .concat(convertTokens)
      : convertTokens;

    this.loadExchangeRatesRequest(exchangeRateTokens).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.smartWalletStateService.updateExchangeRates(response.data);
        }

        this.smartWalletStateService.setIsLoadingExchangeRates(false);
      },
      error: () => {
        this.toastrService.error('Error loading exchange rates.');
        this.smartWalletStateService.setIsLoadingExchangeRates(false);
      }
    });
  }

  public loadNfts(): void {
    if (this.smartWalletStateService.isLoadingNftsSnapshot) {
      return;
    }

    this.smartWalletStateService.setIsLoadingNfts(true);

    this.smartWalletStateService.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap((address) => this.loadNFTsRequest(address))
      )
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            console.log('nfts response', response);
            this.smartWalletStateService.updateNfts(response.data.result);
          }

          if (response.data?.cursor) {
            this.smartWalletStateService.setNftsPaginationKey(response.data.cursor);
          }

          this.smartWalletStateService.setIsLoadingNfts(false);
        },
        error: () => {
          this.smartWalletStateService.setIsLoadingNfts(false);
        }
      });
  }

  public loadTransactions(): void {
    if (this.smartWalletStateService.isLoadingTransactionsSnapshot) {
      return;
    }

    this.smartWalletStateService.setIsLoadingTransactions(true);

    this.smartWalletStateService.address$
      .pipe(
        filter((address) => !!address),
        take(1),
        switchMap((address) => this.loadTransactionsRequest(address))
      )
      .subscribe({
        next: (response) => {
          if (response.success && response.data) {
            console.log('transactions response', response);

            this.smartWalletStateService.updateTransactions(response.data.result);

            if (!response.data?.cursor) {
              this.smartWalletStateService.setIsLoadingTransactions(false);
            }
          } else {
            this.smartWalletStateService.setIsLoadingTransactions(false);
          }
        },
        error: () => {
          this.smartWalletStateService.setIsLoadingTransactions(false);
        }
      });
  }

  private loadNativeTokenRequest(address: string): Observable<Response<{ balance: string }>> {
    return this.http.get<Response<MoralisTokenResponse>>(
      `${environment.gaiminApi}/moralis/native?address=${address}&chain=${this.chain}`
    );
  }

  private loadTokensRequest(address: string): Observable<Response<{ tokens: MoralisTokenResponse[] }>> {
    return this.http.get<Response<{ tokens: MoralisTokenResponse[] }>>(
      `${environment.gaiminApi}/moralis/token?address=${address}&chain=${this.chain}`
    );
  }

  private loadExchangeRatesRequest(tokens: ExchangeRateToken[]): Observable<Response<ExchangeRate>> {
    return this.http.post<Response<ExchangeRate>>(`${environment.gaiminApi}/sw/exchange-rate`, {
      currencies: tokens
    });
  }

  private loadNFTsRequest(address: string): Observable<Response<MoralisResponse<MoralisNFTResponse[]>>> {
    return this.http.get<Response<MoralisResponse<MoralisNFTResponse[]>>>(
      `${environment.gaiminApi}/moralis/nft?address=${address}&chain=${this.chain}&cursor=${this.smartWalletStateService.nftsPaginationKeySnapshot}`
    );
  }

  private loadTransactionsRequest(
    address: string
  ): Observable<Response<MoralisResponse<MoralisTransactionResponse[]>>> {
    const apiUrl = `${environment.gaiminApi}/moralis/history?address=${address}&chain=${this.chain}`;

    const initialRequest = this.http.get<Response<MoralisResponse<MoralisTransactionResponse[]>>>(apiUrl);

    return initialRequest.pipe(
      expand((response) => {
        if (response.data?.cursor) {
          return this.http.get<Response<MoralisResponse<MoralisTransactionResponse[]>>>(
            apiUrl + `&cursor=${response.data.cursor}`
          );
        }

        return EMPTY;
      })
    );
  }

  public getFee(data: TransactionData): Observable<number> {
    return this.http
      .post<Response<Estimate>>(`${environment.gaiminApi}/sw/estimate`, {
        playerId: this.openfortUserSrc.value.id,
        fromAddress: this.smartWalletStateService.addressSnapshot,
        ...data
      })
      .pipe(
        map((response) => Number(response.data?.estimatedTXGasFeeToken) / 10 ** gmrxDecimals),
        catchError((_, caught) => {
          this.toastrService.error('Something went wrong, please try again later');
          return caught;
        })
      );
  }

  public sendTransactionRequest(data: TransactionData): Observable<Response<TransactionResponse>> {
    return this.http.post<Response<TransactionResponse>>(`${environment.gaiminApi}/sw/intent`, {
      playerId: this.openfortUserSrc.value.id,
      fromAddress: this.smartWalletStateService.addressSnapshot,
      ...data
    });
  }

  public async sendSignatureTransactionIntentRequest(transactionId: string, signature: string) {
    return this.openfort.sendSignatureTransactionIntentRequest(transactionId, signature);
  }

  public logout(): void {
    if (this.openfort) {
      this.openfort.logout();
    }

    this.openfortUserSrc.next(null);
    this.smartWalletStateService.logout();
  }
}
